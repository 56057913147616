<template>
  <div>
    <vs-card id="users-view">
      <div slot="header">
        <vs-row vs-align="center">
          <vs-col vs-w="8">
            <h3>
              {{ lang.companies.list.title[languageSelected] }} ({{
                companies.length
              }})
            </h3>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-w="4">
            <vs-button :to="{ path: '/companies/add' }">
              {{ lang.companies.list.add[languageSelected] }}
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
      <div>
        <companies-table
          v-if="filteredCompanies.length > 0"
          :data="filteredCompanies"
          :max-items="10"
          :on-open-filters="openFilters"
        />
      </div>
    </vs-card>

    <!-- filters -->
    <vs-sidebar
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="sidebarx sidebar-filters"
      spacer
      v-model="showFilters"
    >
      <div class="filters">
        <Filters
          type="flat"
          :filter-model="filterModel"
          @init="filterChange"
          @on-apply="onApplyFilters"
        />
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Companies',
  components: {
    CompaniesTable: () => import('./CompaniesTable.vue'),
    Filters: () => import('../metrics/filters/Filters.vue')
  },
  data() {
    return {
      showFilters: false,
      filterModel: [],
      filters: {
        status: 'all'
      }
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('companies', ['companies']),
    filteredCompanies() {
      if (this.filters.status === 'all') return this.companies
      const enabledStatus = this.filters.status === 'enabled'
      return this.companies.filter(d => d.enabled === enabledStatus)
    }
  },
  methods: {
    ...mapActions('companies', ['getCompanies']),
    async initCompanies() {
      this.$vs.loading()
      try {
        await this.getCompanies()
        this.$vs.loading.close()
      } catch (error) {
        console.error(error)
        this.$vs.loading.close()
      }
    },
    onApplyFilters(filters) {
      const filtersTypes = Object.keys(filters)
      filtersTypes.forEach(filter => this.filterChange(filters[filter]))
    },
    filterChange(filter) {
      switch (filter.type) {
        case 'companyStatus':
          this.filters.status = filter.value.status
          break
        default:
          break
      }
    },
    initFilter() {
      this.filterModel = [
        {
          name: 'companyStatusFilter',
          component: 'CompanyStatusFilter'
        }
      ]
    },
    async openFilters() {
      await this.$nextTick()
      this.showFilters = true
    },
    closeSidebar() {
      document.getElementsByClassName('sidebar-filters')[0].style.display =
        'none'
    }
  },
  mounted() {
    this.initFilter()
    /**
     * companies are loaded on login
     * to know if the user should be redirected to
     * create company or companies list
     */
    if (!this.companies || this.companies.length === 0) {
      this.initCompanies()
    }
  },
  beforeDestroy() {
    this.closeSidebar()
  }
}
</script>

<style lang="scss">
.sidebar-filters {
  .vs-sidebar {
    z-index: 99999;
    max-width: 400px;

    .vs-sidebar--items {
      overflow-y: auto;
      overflow-x: visible;
      display: flex;
      flex-direction: column;

      .filters {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 20px;

        &__title {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
